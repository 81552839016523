<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-title>
      <h3>Backup</h3>
    </v-card-title>
    <v-card-title>
      <v-btn @click="createDump">Aktuelle Datenbank sichern</v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dumps"
      :pagination.sync="pagination"
    >
      <template v-slot:items="props">
        <td>{{ props.item.progress }}%</td>
        <td class="text-xs-right">{{ props.item.created_at }}</td>
        <td class="text-xs-right">
          <v-icon v-if="props.item.error">error</v-icon>
          <template v-else>
            <v-btn
              :disabled="!props.item.finished"
              :loading="!props.item.finished"
              @click.prevent.stop="
                downloadDump(props.item.id, props.item.files[0])
              "
              class="ml-2"
            >
              <v-icon left>cloud_download</v-icon>
              Stamm/Zahlungsdaten
            </v-btn>
            <v-btn
              :disabled="!props.item.finished"
              :loading="!props.item.finished"
              @click.prevent.stop="
                downloadDump(props.item.id, props.item.files[1])
              "
              class="ml-2"
            >
              <v-icon left>cloud_download</v-icon>
              Posteingang
            </v-btn>
          </template>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import serviceApi from '@/api/serviceApi'

export default {
  name: 'Backup',
  components: {},
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
      },
      headers: [
        { text: 'Fortschritt', value: 'progress' },
        { text: 'Erstellt', value: 'created_at', align: 'right' },
        { text: '', value: 'actions', align: 'right' },
      ],
      dumps: [],
      updateInterval: null,
    }
  },
  methods: {
    async updateDumps() {
      if (!this.updateInterval) {
        this.updateInterval = setInterval(() => {
          const isAllFinished = this.dumps.find(
            (dump) => dump.finished === false
          )
          if (!isAllFinished) {
            clearInterval(this.updateInterval)
            this.updateInterval = null
          } else {
            this.getAllDumps()
          }
        }, 1000)
      }
    },
    async getAllDumps() {
      const result = await serviceApi.getAllDumps()
      this.dumps = result.data
      this.updateDumps()
    },
    async createDump() {
      await serviceApi.createDump()
      this.getAllDumps()
    },
    downloadDump(dumpQueue, fileName) {
      serviceApi.downloadDump(dumpQueue, fileName)
    },
  },
  created() {
    this.getAllDumps()
  },
  destroyed() {
    clearInterval(this.updateInterval)
  },
}
</script>

<style></style>
